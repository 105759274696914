import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackingService } from '@usheru-hff/shared/utils-tracking';
import { AnswerSelection } from '../entities/answer-selection.model';
import { CompetitionAnswer } from '../entities/competition-answer.model';
import { CompetitionQuestion } from '../entities/competition-question.model';
import { Competition } from '../entities/competition.model';
import { Movie } from '@usheru-hff/shared/domain';

@Component({
  selector: 'ush-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  @Input()
  movie!: Movie;

  @Input()
  question!: CompetitionQuestion;

  @Input()
  competition!: Competition;

  @Output()
  answerPicked = new EventEmitter<AnswerSelection>();

  selection!: CompetitionAnswer;

  constructor(private trackingService: TrackingService) {}

  pickAnswer(answer: CompetitionAnswer): void {
    this.selection = answer;
    this.answerPicked.next({
      questionId: this.question.id,
      answer: answer
    });

    this.trackingService.trackEvent({
      action: 'SelectAnswer',
      properties: {
        category: 'Competitions',
        label: `${this.competition.title} @ ${this.movie?.title}`,
        movie: this.movie?.title,
        idMovie: this.movie?.id,
        tagsMovie: this.movie?.tags.map(tag => tag.friendlyUrl),
        genresMovie: this.movie?.genres.map(genre => genre.slug),
        imdb: this.movie?.imdb,
        competitionTitle: this.competition.title,
        idCompetition: this.competition.id
      }
    });
  }
}
