import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompetitionComponent } from './competition.component';
import { QuestionComponent } from './question/question.component';
import { RouterModule } from '@angular/router';
import { COMPETITIONS_ROUTES } from './competition.routes';
import { SharedUtilsTranslationModule } from '@usheru-hff/shared/utils-translation';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MovieDetailFeatureSubscribeModule } from '@usheru-hff/movie-detail/feature-subscribe';
import { MovieDetailUiMovieElementsModule } from '@usheru-hff/movie-detail/ui-movie-elements';
import { SharedUtilsModule } from '@usheru-hff/shared/utils';
import { SharedUtilsImageModule } from '@usheru-hff/shared/utils-image';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(COMPETITIONS_ROUTES),
    SharedUtilsTranslationModule,
    FontAwesomeModule,
    MovieDetailFeatureSubscribeModule,
    MovieDetailUiMovieElementsModule,
    SharedUtilsModule,
    SharedUtilsImageModule
  ],
  declarations: [CompetitionComponent, QuestionComponent],
  exports: [CompetitionComponent]
})
export class MovieDetailFeatureCompetitionsModule {}
