<div class="question-wrapper">
  <img *ngIf="question.imageUrl" [src]="question.imageUrl | img : { width: 800 }" [alt]="question.title + ' question picture'" class="question-image" />
  <div class="question-content">
    <div class="question">
      {{ question ? question.title : 'no question' }}
    </div>

    <div class="answers">
      <div *ngFor="let answer of question.answers" class="answer" (click)="pickAnswer(answer)">
        <img *ngIf="answer.imageUrl" [src]="answer.imageUrl | img : { width: 800 }" class="answer-image" [alt]="'Image for answer ' + answer?.answerNumber" />

        <div class="answer-text" [ngClass]="answer == selection ? 'answer-text-selected' : 'answer-text'">
          <div>{{ answer.title }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
